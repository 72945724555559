import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import FileUploadSingle from "./FileUploadSingle";
import RecordSound from "./RecordSound";
import RecordSound2 from "./RecordSound2";

import { useForm} from "react-hook-form";
import { useState } from "react";
import Button from '@mui/material/Button';


//const ENDPOINT_PREFIX = "http://localhost:5117/api/";
//const ENDPOINT_PREFIX = "https://aiscultatio-ai.jhorak.eu/api/";
const ENDPOINT_PREFIX = "https://test-ai-api.aiscultatio.com/api/";


function App() {

  const [info, setInfo] = useState('');
  const [fileUploadGuid, setFileUploadGuid] = useState('');


  const {
    handleSubmit
  } = useForm();


  async function fetchUserLogin() {

      const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
      
    };

    const res = await fetch(ENDPOINT_PREFIX + "tools/info", requestOptions);
    res
      .json()
      .then(
        res => {
          setInfo(res);

          console.log(res);

        })
      .catch(
        err => {
          setInfo('Error');
          console.log("error");
          }
      );
  };

  const buttonClick = () => {
    fetchUserLogin();
  }

  function handleChangeFileUploadGuid(newValue:string) {
    setFileUploadGuid(newValue);
  }

  return (
    <div className="App">
    <h1>AIscultatio</h1>

    <FileUploadSingle pathApi={ENDPOINT_PREFIX} param2='' fileUploadGuid={fileUploadGuid} onChange={handleChangeFileUploadGuid}/>

         {/* <RecordSound pathApi={ENDPOINT_PREFIX} param2='' /> */}

     <RecordSound2 pathApi={ENDPOINT_PREFIX} param2='' fileUploadGuid={fileUploadGuid} onChange={handleChangeFileUploadGuid} /> 


    <h2>Test</h2>
    <Grid container spacing={2} >
        <Grid item xs={12}>    
            <Button  onClick={buttonClick} variant="outlined"> App info
            </Button >
            {info}
        </Grid>
    </Grid>
    .

    </div>
  );
}

export default App;
